import charter from "../assets/icons/menu/charter.svg";
import comity from "../assets/icons/menu/comity.svg";
import members from "../assets/icons/menu/members.svg";
import book from "../assets/icons/menu/book.svg";
import mesVaccins from "../assets/images/partners/mes-vaccins-net-tiny.png";
import spilf from "../assets/images/partners/spilf.png";
import cmit from "../assets/images/partners/cmit.png";

import abreviations from "../assets/icons/menu/abreviations.svg";
import questionMark from "../assets/icons/menu/question-mark.svg";

import {
  HeaderMenuItem,
  HeaderMenuSection,
} from "../components/main/HeaderMenu";

const aboutMenu: HeaderMenuSection[] = [
  {
    title: "ePOPI",
    items: [
      {
        label: "Comité editorial",
        to: "/comite-editorial",
        icon: comity,
      },
      {
        label: "Charte ePOPI",
        to: "/charte-epopi",
        icon: charter,
      },
    ],
  },
  {
    title: "CMIT",
    items: [
      {
        label: "CMIT",
        to: "/le-cmit",
        icon: cmit,
      },
      {
        label: "Les membres",
        to: "/membres-du-cmit",
        icon: members,
      },
      {
        label: "Ouvrages",
        to: "/ouvrages",
        icon: book,
      },
    ],
  },
  {
    title: "Partenaires",
    items: [
      {
        label: "SPILF",
        to: "https://www.spilf.org",
        externalLink: true,
        description: "Petite phrase explicative sur le partenariat",
        icon: spilf,
      },
      {
        label: "MesVaccins.net",
        to: "https://mes-vaccins.net",
        externalLink: true,
        description: "Petite phrase explicative sur le partenariat",
        icon: mesVaccins,
      },
    ],
  },
];

const ressourcesMenu: HeaderMenuSection[] = [
  {
    items: [
      {
        label: "Abréviations",
        to: "/abreviations",
        icon: abreviations,
      },
      {
        label: "Actualités de la SPILF",
        externalLink: true,
        to: "https://www.infectiologie.com/fr/actualites.html",
        icon: spilf,
      },
      {
        label: "FAQ",
        to: "/faq",
        icon: questionMark,
      },
    ],
  },
];

export const getHeaderMenuSections = (menu: "about" | "ressources") => {
  switch (menu) {
    case "about":
      return aboutMenu;
    case "ressources":
      return ressourcesMenu;
    default:
      return [];
  }
};

export const getHeaderSubMenuSections = (menu: "about" | "ressources") => {
  switch (menu) {
    case "about":
      return aboutMenu.reduce((acc, item) => {
        return acc.concat(item.items);
      }, [] as HeaderMenuItem[]);
    case "ressources":
      return ressourcesMenu.reduce((acc, item) => {
        return acc.concat(item.items);
      }, [] as HeaderMenuItem[]);
    default:
      return [];
  }
};
