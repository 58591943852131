import { get } from "idb-keyval";
import { useParams } from "react-router-dom";
import { GetAllDataVaccins } from "../../types/api/allDatas";
import { useCallback, useEffect, useState } from "react";

const Vaccin = () => {
  const params = useParams();
  const { id } = params;
  const [url, setUrl] = useState<string>("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);
  const getUrlCallback = useCallback(async () => {
    const vaccin = await get<GetAllDataVaccins>("vaccin-" + id);
    setUrl(vaccin?.url);
  }, [id]);

  useEffect(() => {
    if (id) getUrlCallback();
  }, [id, getUrlCallback]);

  return (
    <div
      style={{
        position: "relative",
        height: height * 0.8,
        width: width * 0.9,
      }}
    >
      <iframe
        allowFullScreen={true}
        id={id}
        title="vaccin"
        src={url}
        style={{
          border: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </div>
  );
};

export default Vaccin;
