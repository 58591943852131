import { Button } from "@mui/material";
import { useBlocker, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../store/hook";
import { deleteUserRegistration } from "../../store/reducers/auth.slice";

const Step3 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  dispatch(deleteUserRegistration());

  const regex = new RegExp("/inscription/etape-(1|2)");
  useBlocker(({ nextLocation }) => regex.test(nextLocation.pathname));

  return (
    <div className="step3-container">
      <p>
        Il ne nous reste plus qu’à vérifier l’adresse email : un message a été
        envoyé à l’adresse email que vous avez renseigné pour créer votre
        compte.
        <br />
        Suivez les instructions pour valider cette ultime vérification.
        <br />
        <br />
        Si le message n’arrive pas, vérifiez votre dossier “spams” ou
        “indésirables”.
      </p>

      <Button variant="contained" size="large" onClick={() => navigate("/")}>
        Page d'accueil
      </Button>
    </div>
  );
};

export default Step3;
